.App {
  text-align: center;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.buttons{
  grid-row: 4;
  grid-column: 1 / span 4;
  display: flex;
  justify-content: space-evenly;
}

.projects-grid{
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  padding: 20px;
}

.two-cols{
  grid-template-columns: 1fr 1fr;
}

.three-cols{
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 1100px) and (min-width: 801px) {
  .three-cols{
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 800px){
  .two-cols{
    grid-template-columns: 1fr;
  }
  .three-cols{
    grid-template-columns: 1fr;
  }
  .grid-title{
    font-size: 80%;
  }
}

.projects-grid .grid-title{
  grid-column: 1 / -1;
  grid-row-start: 1;
  align-self: center;
  padding: 5px;
  background-color: #175a85 ;
  color: white;
  box-shadow:3px 5px 15px 5px rgba(0,0,0,0.3);
  /*font-family: 'Courier New', Courier, monospace;*/
  font-size: 30pt;
  font-weight: bolder;
}

.projects-grid h2{
  color:black;
  /*font-family: 'Courier New', Courier, monospace;*/
}

.projects-grid .item{
  width: 100%;
  height: max-content;
  background-color: white;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow:3px 5px 15px 5px rgba(0,0,0,0.3);
  cursor: pointer;
}



.main-body{
  display:grid;
 
}

.App-link {
  color: #61dafb;
}

