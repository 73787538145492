

.closebutton {
    display: flex;
    position: absolute;
    top: 5px;
    right: 5px;
    justify-self: flex-end;
  }
  
  .header{
    position: relative;
  }
  
  .modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: whitesmoke;
    box-shadow: 2px, 2px, 1px rgba(0,0,0,0.3);
    margin: auto;
    width: 90%;
    height: 100%;
    box-sizing: border-box;
    align-content: center;
    overflow-y: scroll;

    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
    border-radius: 10px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(75, 75, 77);
    opacity: 0.8;
  }

  .bodycontainer{
      display: flex;
      justify-items: center;
      align-items: center;
      flex-direction: row;
     /* font-family: 'Courier New', Courier, monospace;*/
      font-size: 18pt;
      margin: 15px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: left;
  }

  .bodyText{
    padding-left: 30px;
  }

  .button{
    display: flex;
    justify-items: center;
    justify-self:center;
    align-self: center;
  }

  h2{
    justify-self: center;
    align-self: center;
  }

  @keyframes fadeIn {
    from {opacity:0;}
    to {opacity: 100;}
  }